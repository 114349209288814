
#speed-testC{
  display: none;
}

body {
  color: whitesmoke;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  margin: 0;
  padding: 0;
}

.title {
  position: absolute;
  text-align: center;
  width: calc(var(--pseudo-vw)*100);
  font-family: "hobobold";
  top: 30px;
  font-size: clamp(1px, calc(var(--pseudo-vw)*6), 50px);
}

#likesC {
  position: absolute;
  width: calc(var(--pseudo-vw)*100);
  /* left: calc(var(--pseudo-vw)*10); */
  top: clamp(1px, calc(var(--pseudo-vw)*6), 50px);
  padding-top: 50px;
}

.likes-row {
  display: flex;
  align-items: center;
}

.likes-column {
  flex: 50%;
  padding: 0px;
}

.likes-row {
  position: relative;
}

#carouselC {
  position: relative;
  box-sizing: border-box;
  perspective: 500px;
  transition: opacity 1.5s;
}

.carousel {
  position: absolute;
  width: calc(var(--c-width));
  height: calc(var(--c-width));
  top: -100%;
  right: -100%;
  left: -100%;
  bottom: -100%;
  margin: auto;
  transform-style: preserve-3d;
  animation: rotate360 60s infinite forwards linear;
}

.carousel-face {
  position: absolute;
  width: calc(var(--c-width));
  height: calc(var(--c-width));
  background-size: cover;
}

.singles-image {
  transition: transform 2s;
  border-radius: 50%;
}

#singles1-image {
  transform: rotate(var(--singles1-rotation));
}

#singles2-image {
  transform: rotate(var(--singles2-rotation));
}

@keyframes fade-out-in {
  0% {
    opacity: 1;
  }

  30% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fade-out-in {
  animation-name: fade-out-in;
}

.slide {
  position: relative;
}

.hand-pointer {
  background-size: cover;

}

.singles-cover {
  background-size: cover;
  margin: auto;
  animation-duration: 1s;
  transition: ease-in;
}

.youtube-play {
  position: fixed;
  background-color: rgba(245, 245, 245, 0.4);
}

#youtube-play-img1, #youtube-play-img2 {
  width: 100%;
  display: none;
}

@media screen and (orientation: portrait) {

  .hand-pointer {
    position: fixed;

    width: calc(var(--pseudo-vw)*6);
    height: calc(var(--pseudo-vw)*6);
    margin-left: calc(var(--pseudo-vw)*25);
    margin-top: calc(var(--pseudo-vw)*-6);
  }

  .youtube-play {
    width: calc(var(--pseudo-vw)*14);
    height: calc(var(--pseudo-vw)*14);

    margin-left: calc(var(--pseudo-vw)*12);
    margin-top: calc(var(--pseudo-vw)*12);
  }

  .singles-image {
    margin-top: 5px;
  }

  .singles-row,
  .singles-column {
    border: none;
  }

  .singles-row {
    padding-top: calc(var(--pseudo-vh)*5);
  }

  .singles-image {
    width: calc(var(--pseudo-vw)*40);
  }

  .singles-cover {
    width: calc(var(--pseudo-vw)*38);
    height: calc(var(--pseudo-vw)*38);
  }

/*   #singles1-cover-image {
    background-image: url("./img/500/singles1.png");
  }

  #singles2-cover-image {
    background-image: url("./img/500/singles2.png");
  } */

  @media screen and (orientation: portrait) {

    :root {
      --c-width: calc(var(--pseudo-vw)*20);
      --radius: calc(var(--c-width)*1.4);
    }

    #carouselC {
      margin-top: calc(var(--pseudo-vh)*11);
    }

    .carousel-face:nth-child(1) {
      transform: rotateX(0deg) translateZ(calc(var(--radius)));
    }

    .carousel-face:nth-child(2) {
      transform: rotateX(40deg) translateZ(calc(var(--radius)));
    }

    .carousel-face:nth-child(3) {
      transform: rotateX(80deg) translateZ(calc(var(--radius)));
    }

    .carousel-face:nth-child(4) {
      transform: rotateX(120deg) translateZ(calc(var(--radius)));
    }

    .carousel-face:nth-child(5) {
      transform: rotateX(160deg) translateZ(calc(var(--radius)));
    }

    .carousel-face:nth-child(6) {
      transform: rotateX(200deg) translateZ(calc(var(--radius)));
    }

    .carousel-face:nth-child(7) {
      transform: rotateX(240deg) translateZ(calc(var(--radius)));
    }

    .carousel-face:nth-child(8) {
      transform: rotateX(280deg) translateZ(calc(var(--radius)));
    }

    .carousel-face:nth-child(9) {
      transform: rotateX(320deg) translateZ(calc(var(--radius)));
    }

    @keyframes rotate360 {
      from {
        transform: rotateX(0deg);
      }

      to {
        transform: rotateX(-360deg);
      }
    }


  }

  .stores-grid {
    grid-template-columns: repeat(4, 1fr);
    margin-top: calc(var(--pseudo-vw)*14);
    grid-auto-rows: calc(var(--pseudo-vw)*13);
  }

  .store-grid-item {
    height: calc(var(--pseudo-vw)*8);
    width: calc(var(--pseudo-vw)*8);
  }

  .store-grid-item {
    height: calc(var(--pseudo-vw)*8);
    width: calc(var(--pseudo-vw)*8);
  }

  .store-grid-item-name {
    font-size: calc(var(--pseudo-vw)*3);
    height: calc(var(--pseudo-vw)*4);
  }
}

@media screen and (orientation: landscape) {

  @media screen and (orientation: landscape) {

    :root {
      --c-width: calc(var(--pseudo-vh)*22);
      --radius: calc(var(--c-width)*1.4);
    }

    #carouselC {
      margin-top: 75px;
    }

    .carousel-face:nth-child(1) {
      transform: rotateY(0deg) translateZ(calc(var(--radius)));
    }

    .carousel-face:nth-child(2) {
      transform: rotateY(40deg) translateZ(calc(var(--radius)));
    }

    .carousel-face:nth-child(3) {
      transform: rotateY(80deg) translateZ(calc(var(--radius)));
    }

    .carousel-face:nth-child(4) {
      transform: rotateY(120deg) translateZ(calc(var(--radius)));
    }

    .carousel-face:nth-child(5) {
      transform: rotateY(160deg) translateZ(calc(var(--radius)));
    }

    .carousel-face:nth-child(6) {
      transform: rotateY(200deg) translateZ(calc(var(--radius)));
    }

    .carousel-face:nth-child(7) {
      transform: rotateY(240deg) translateZ(calc(var(--radius)));
    }

    .carousel-face:nth-child(8) {
      transform: rotateY(280deg) translateZ(calc(var(--radius)));
    }

    .carousel-face:nth-child(9) {
      transform: rotateY(320deg) translateZ(calc(var(--radius)));
    }

    @keyframes rotate360 {
      from {
        transform: rotateY(0deg);
      }

      to {
        transform: rotateY(-360deg);
      }
    }
  }

  .hand-pointer {
    position: fixed;
    width: calc(var(--pseudo-vh)*6);
    height: calc(var(--pseudo-vh)*6);
    margin-left: -16px;
    margin-top: calc(var(--pseudo-vh)*-8);
  }

  .youtube-play {
    width: calc(var(--pseudo-vh)*15);
    height: calc(var(--pseudo-vh)*15);
    margin-top: calc(var(--pseudo-vh)*16.5);
    margin-left: calc(var(--pseudo-vh)*16.5);
  }

  .singles-row {
    display: flex;
    flex-direction: row-reverse;
    padding-top: 35px;
    padding-right: calc(var(--pseudo-vw)*1);
    align-items: center;
  }

  .singles-column {
    flex: 25%;
    padding: 0px;
  }

  #singles1-col1,
  #singles2-col1 {
    text-align: left;
    margin-left: 5px;
  }

  #singles1-col2 {
    text-align: right;

  }

  .singles2-col1 {

    position: relative;

  }

  .singles-image {
    width:  calc(var(--pseudo-vh)*50);
  }

  .singles-cover {
    width: calc(var(--pseudo-vh)*48);
    height: calc(var(--pseudo-vh)*48);
  }

/*   #singles1-cover-image {
    background-image: url("./img/500/singles1.png");
  }

  #singles2-cover-image {
    background-image: url("./img/500/singles2.png");
  } */

  .stores-grid {
    grid-template-columns: repeat(5, 1fr);
    margin-top: calc(var(--pseudo-vh)*14);
    grid-auto-rows: calc(var(--pseudo-vh)*13);
  }

  .store-grid-item {
    height: calc(var(--pseudo-vh)*8);
    width: calc(var(--pseudo-vh)*8);
  }

  .store-grid-item-name {
    font-size: calc(var(--pseudo-vh)*3);
    height: calc(var(--pseudo-vh)*4);
  }
}

.stores-grid {
  display: grid;
}

.store-grid-item {

  border-radius: 3px;
  background-size: cover;
  margin: auto;
}

.store-grid-item-name {
  white-space: nowrap;
}

a {
  color: whitesmoke;
}

a:link {
  text-decoration: none;
}

#CookiebotWidget {
  bottom: 15px !important;
}

.cookiebotWidget-logo {
  outline-color: transparent !important;
}

#play-buttonC {
  position: absolute;
  bottom: 15px;
  right: 10px;
}

.fp-slidesNav {
  padding-bottom: 5px;
}

.fp-controlArrow {
  display: none;
}

.fp-slidesNav ul li a span {
  background-color: whitesmoke !important;
}

.map-area {
  cursor: pointer;
}